import GET_ARTICLES from "../class/getArticles";

const [ d, w ] = [ document, window ];

const domReady = () => {
  // Promise.all([window.load['recruit']]).then( () => new recruitBLOCK );
  new recruitBLOCK;
};

class recruitBLOCK {
  constructor() {
    this.guideline();
    this.pagelink();
  }

  guideline () {
    const is_career = d.body.classList.contains('is--article-2');
    const is_message = d.body.classList.contains('is--article-3');
    if( !is_career && !is_message ){
      new Promise(function(resolve) {
        setTimeout(() => resolve(), 500);
      }).then(() => {
        const getArticle = new GET_ARTICLES('.guideline_list');
        getArticle.makeItem = (item) => `
        <li>
          <a href="${item.href}">
            <figure><img src="${item.thumb}" alt="${item.title}"></figure>
            <p>${item.title}</p>
            <svg class="link_arrow"><use xlink:href="#arrow"></use></svg>
          </a>
        </li>`;
        getArticle.render();
      });
    }
  }

  pagelink () {
    const is_top = d.body.classList.contains('is--article-1');
    const is_career = d.body.classList.contains('is--article-2');
    const is_message = d.body.classList.contains('is--article-3');

    if( is_top ){
      $('.pagelink .top').addClass('current');
    } else if ( is_career ) {
      $('.pagelink .career').addClass('current');
    } else if ( is_message ) {
      $('.pagelink .message').addClass('current');
    } else {
      $('.pagelink .require').addClass('current');
    }
  }
}

export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  // d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(w).on('load', domReady);
}