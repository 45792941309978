import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.news();
    this.mainvisual();
  }

  mainvisual() {
    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mv-pager",
        clickable: true,
      },
    });

    let Mv = document.querySelector('.mainvisual');
    w.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });
  }

  news () {
    // mainvisual news
    const news_ticker = new GET_ARTICLES('.mainvisual .news_wrap[data-plugin="getArticles"]');
    news_ticker.makeItem = (item,content) => `
    <a href="${item.href}">
      <div class="info">
        <p class="ttl">NEWS</p>
        <p class="date">${item.date}</p>
        <p class="cate">${item.category.name}</p>
      </div>
      <div class="article_ttl">
        <p><span>${item.title}</span></p>
        <svg class="link_arrow"><use xlink:href="#arrow"></use></svg>
      </div>
    </a>`;
    // news_ticker.makeItem = (item) => console.log(item);
    news_ticker.render();
    
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl"><span>${item.title}</span></p>
      </a>
    </li>`;

    getArticle.render();
  }

}


const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}