import TAGSORT,{taglist} from "../class/tagsort";

const [ d, w ] = [ document, window ];

const relatedItemTemplate =  `
  <div class="related_wrap" v-if="relatedArticles.length">
    <p class="related_title">その他のお客様</p>
    <ul class="rwd002-list-content__list">
      <li class="rwd002-list-content__item" v-for="(article , ind) in relatedArticles">
        <a class="rwd002-list-content__link" :href="article.href">
          <figure class="rwd002-list-content__thumb">
            <img class="rwd002-list-content__thumb-img" :src="article.thumb" :alt="article.title">
          </figure>
          <div class="rwd002-list-content__body">
            <span class="cate" v-if="article.category.name">{{ article.category.name }}</span>
            <p class="title">{{ article.title }}</p>
          </div>
        </a>
      </li>
    </ul>
  </div>
  `;

class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    // const container = d.querySelector( this.opt.container || '.rwd002-container');
    // const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    // const is_list = d.body.classList.contains('is--list');
    // if( !container ) return false;

    // top
    // container.insertAdjacentHTML('beforebegin', tag);
    // if( is_list ){
    // }
  }
}

const domReady = () => {
  const is_list = d.body.classList.contains('is--list');

  const leadText = `<p class="lead_text">当所をご利用いただいている<br class="sp">お客様をご紹介いたします</p>`;

  if( is_list ){
    $(leadText).prependTo('.rwd002-main');
  } else {
    const Tagsort = new TAGSORT({
      related: {
        container: '.rwd002-pagination',
        itemTemplate: relatedItemTemplate
      }
    });
  
    d.addEventListener('tagsort.beforeInit', e => {
      const TAGSORT = e.detail;
      Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
    });
  
    Tagsort.render();

    $('.rwd002-block.is-head').insertBefore('footer');
  }

};

export default function INTRO () {
  $(w).on('load', domReady);
}